.register {
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-image: url('../../../static/img/landingPage/Fondo_home.jpg');
  background-position: top;
  background-repeat: no-repeat;
}

.register #card {
  place-items: center;
  display: flex;
  height: 30em;
  width: 40em;
}

.register h5 {
  text-align: center;
  color: #090053;
}

.position {
  display: flex;
  place-content: center;
}

.register .space {
  margin-top: 2.5%;
}

.register .space button {
  margin-top: 5%;
}

.register .MuiInputBase-input {
  min-width: 20px;
  background-color: transparent;
  color: #575757;
  padding: 10px 50px 10px 15px;
  border-radius: 12px;
  border: 2px solid #090053;
  font-size: 15px;
 }

.register .label {
  font-size: 15px;
  text-align: center;
  color: #2dd0a4;
  font-display: swap;
}

.register svg {
  cursor: pointer;
  margin: 7;
  color: #090053;
}

.register .MuiSvgIcon-root {
  margin-top: 1.4%;
  margin-left: -1.5%;
}

.register .form {
  min-width: 250px;
  max-width: 350px;
}
.register .registerUser{
    margin-left: -50px;
    
}

.register .MuiFormControl-root .MuiTextField-root{
  width: 15em;
}

.register button.buttonRegister {
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  text-transform: capitalize;
}

.register button.buttonRegister:hover {
  background-color: #2dd0a4;
}

