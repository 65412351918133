.logo {
  float: left;
  text-align: left;
  margin-right: 82em;
  width: 200px;
}

@media screen and (min-width: 600px){
.containerIcon {
  position: absolute;
  margin-left: 95%;
}
.containerIconHelp{
  position: absolute;
  margin-left: 95%;
  margin-right: 10%; 
}
.popover .MuiPaper-rounded{
  margin-top: 8px;
  margin-right: 6px;
  border-radius: 8px !important;
}
}




