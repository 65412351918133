.containerBoard {
  max-width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  margin-top: 90px;
}
.cardMui {
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2),
    0 12px 24px -4px rgba(145, 158, 171, 0.12) !important;
  border-radius: 12px !important;
}
.cardMui .toolbarSx {
  padding: 0, 1, 0, 3;
  height: 96px;
}

.popoverTable .MuiPaper-rounded {
  border-radius: 0px !important;
}

.alertSx {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.alertSx .MuiAlert-icon {
  font-size: 3em;
}
.titleType {
  font-size: 2em;
  text-align: left;
  padding-top: 1em;
  padding-left: 25px;
  color: var(--blue);
}
.createDash {
  display: flex !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
  width: 40px;
  height: 40px;
  border-radius: 8px !important;
  place-content: center;
  place-items: center;
  background: var(--green) !important;
  cursor: pointer !important;
  color: var(--white) !important;
  position: relative;
  left: 95%;
  top: -35px;
}
.comparator {
  text-align: center;
  margin-block-start: 1em;
}
.btnCompare:active,
.createDash:active {
  transform: scale(0.9);
}

.swal2-container {
  z-index: 2000;
}
/* Estilos Swal*/
.swal2-popup {
  width: 40em;
  height: 30em;
  border-radius: 1em;
}
.swal2-title {
  color: var(--blue);
}
.swal2-icon.swal2-warning {
  border-color: #faa8a8;
  color: #f88686;
}
.swal2-styled.swal2-confirm {
  background-color: var(--green);
  border-radius: 8px;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(102, 224, 177, 0.5);
}
.swal2-styled.swal2-cancel {
  border: 2px solid var(--blue);
  border-radius: 8px;
  background-color: transparent;
  color: var(--blue);
}
