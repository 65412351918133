body {
  font-size: 16px;
}

.homepage {
  width: 100%;
  height: 100%;
}

.homepage .menu {
  position: absolute;
  right: 1.25em;
  top: 1.25em;
}

.logo-home {
  margin-top: 1.875em;
  text-align: center;
}
.logo-home img {
  width: 16em;
}
.home-icon {
  margin-top: 2.5em;
  text-align: center;
  display: flex;
  gap: 0.8em;
}
.home-icon svg {
  color: #090053;
}
.home-icon svg:active {
  transform: scale(0.9);
}
#graphic_resource1 {
  margin-top: 8%;
}

/* .graphic_resource3 {
  margin-top: -10%;
} */

.graphic_resource4 {
  margin-top: 15%;
}

.graphic_resource5 {
  margin-top: 10%;
}

@font-face {
  font-family: 'fuente1';
  src: url('../../static/fuentes/Gotham-Ultra.otf'),
    url('../../static/fuentes/Gotham-Ultra.eot'),
    url('../../static/fuentes/Gotham-Ultra.svg') format('svg'),
    url('../../static/fuentes/Gotham-Ultra.ttf') format('truetype'),
    url('../../static/fuentes/Gotham-Ultra.woff2'),
    url('../../static/fuentes/Gotham-Ultra.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'fuente2';
  src: url('../../static/fuentes/Gotham-Book.eot'),
    url('../../static/fuentes/Gotham-Book.eot'),
    url('../../static/fuentes/Gotham-Book.svg') format('svg'),
    url('../../static/fuentes/Gotham-Book.ttf') format('truetype'),
    url('../../static/fuentes/Gotham-Book.woff2'),
    url('../../static/fuentes/Gotham-Book.woff') format('woff');
  font-display: fallback;
}

#fuente_1 {
  font-family: 'fuente1', sans-serif;
  font-size: 1.6em;
  color: #090053;
  font-display: fallback;
  padding-left: 5%;
}
#fuente_2 {
  font-family: fuente2, sans-serif;
  font-size: 1.6em;
  color: #2dd0a4;
  font-display: fallback;
  float: left;
  padding-right: 2%;
  padding-left: 5%;
}

#fuentes {
  text-align: center;
}

.button1 {
  background-color: var(--green);
  border-radius: 8px;
  text-transform: capitalize;
  padding: 0.6em;
  border: none;
  color: var(--white);
  text-align: center;

  display: inline-block;
  font-size: 22px;
  margin-top: 4%;
  cursor: pointer;
}
.button1:hover {
  color: var(--white);
  box-shadow: 2px 2px 5px rgb(8, 8, 8);
  transition: all 0.2s ease;
  }

.button1:active {
  transform: scale(0.9);
}

.home-icon2 {
  text-align: center;
}
.container-cards {
  place-content: center;
}
.footer {
  background-color: #090053;
  height: 100%;
  width: 100%;
  font-size: 1em;
}

.footer1 {
  height: 70%;
  width: 100%;
  background-color: #090053;
}

#fuente_3 {
  font-family: 'fuente2', sans-serif;
  color: #2dd0a4;
  margin-top: 0%;
  width: 100%;
}

#fuente_4 {
  font-family: 'fuente2', sans-serif;
  color: #ffffff;
  text-align: center;
  margin-top: 0%;
}

.logo-home2 {
  text-align: center;
}

.spacing {
  margin-top: 15%;
}

.pHome {
  color: #424242;
  font-size: 18px;
  text-align: justify;
  margin-top: 25px;
}

/*Extra large*/
@media all and (min-width: 1024px) and (max-width: 1280px) {
  body {
    font-size: 16px;
  }
  #fuente_1 {
    font-size: 1.4em;
    color: #090053;
    padding-left: 5%;
  }
  #fuente_2 {
    font-size: 1.4em;
    color: #2dd0a4;
    padding-right: 2%;
    padding-left: 5%;
  }

  #parrafo_1,
  #parrafo_2,
  #parrafo_3,
  #parrafo_4,
  #parrafo_5 {
    font-size: 1.115em;
    text-align: center;
    margin-right: 0.313em;
  }

  .button1 {
    font-size: 1.2em;
    margin-top: 1.5em;
    margin-bottom: 3.2em;
    margin-left: 2em;
  }

  #button-container {
    display: flex;
    justify-content: center;
  }
}

/*Large*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  body {
    font-size: 14px;
  }
  #fuente_1 {
    font-size: 1.4em;
    color: #090053;
    padding-left: 5%;
  }
  #fuente_2 {
    font-size: 1.4em;
    color: #2dd0a4;
    padding-right: 2%;
    padding-left: 5%;
  }

  #parrafo_1,
  #parrafo_2,
  #parrafo_3,
  #parrafo_4,
  #parrafo_5 {
    font-size: 1.115em;
    text-align: center;
    margin-right: 0.313em;
  }

  .button1 {
    font-size: 1.2em;
    margin-top: 1.4em;
    margin-bottom: 3em;
  }

  #button-container {
    display: flex;
    justify-content: center;
  }
  .footer {
    background-color: #090053;
    height: 100%;
    width: 100%;
    padding-bottom: 1.25em;
    font-size: 0.9rem;
  }

  #mail-container {
    width: fit-content;
  }
}

/*Medium*/
@media all and (min-width: 480px) and (max-width: 768px) {
  body {
    font-size: 18px;
  }
  .home-icon {
    justify-content: center;
    margin-block-start: 1.5em;
  }
  #fuente_1 {
    font-size: 1.4em;
    color: #090053;
    padding-left: 5%;
  }
  #fuente_2 {
    font-size: 1.4em;
    color: #2dd0a4;
    padding-right: 2%;
    padding-left: 5%;
  }

  #parrafo_1,
  #parrafo_2,
  #parrafo_3,
  #parrafo_4,
  #parrafo_5 {
    font-size: 1.115em;
    text-align: center;
    margin-right: 0.313em;
    margin-bottom: 0.5em;
  }

  .button1 {
    font-size: 1.2em;
    margin-top: 1.4em;
    margin-bottom: 3em;
    margin-right: 1em;
  }



  #button-container {
    display: flex;
    justify-content: center;
  }

  .footer {
    background-color: #090053;
    height: 100%;
    width: 100%;
    padding-bottom: 1.25em;
    font-size: 0.9rem;
  }

  #mail-container {
    width: fit-content;
    margin: 0 auto;
  }

  .logo-home2,
  .home-icon2 {
    margin-left: 2em;
  }
}

/*Small*/
@media all and (max-width: 480px) {
  body {
    font-size: 16px;
  }
  .home-icon {
    justify-content: center;
    margin-block-start: 0.5em;
  }
  #fuente_1 {
    font-size: 1.4em;
    color: #090053;
    padding-left: 5%;
  }
  #fuente_2 {
    font-size: 1.4em;
    color: #2dd0a4;
    padding-right: 2%;
    padding-left: 5%;
  }

  #parrafo_1,
  #parrafo_2,
  #parrafo_3,
  #parrafo_4,
  #parrafo_5 {
    font-size: 1.115em;
    text-align: center;
    margin-right: 0.313em;
    margin-bottom: 0.5em;
  }

  .button1 {
    font-size: 1.2em;
    margin-top: 1.4em;
    margin-bottom: 3em;
    margin-right: 1em;
  }

  #button-container {
    display: flex;
    justify-content: center;
  }

  .footer {
    background-color: #090053;
    height: 100%;
    width: 100%;
    margin-top: -14%;
    padding-bottom: 1.25em;
    font-size: 0.9rem;
  }

  #mail-container {
    width: fit-content;
    margin: 0 auto;
  }

  .logo-home2,
  .home-icon2 {
    margin-left: 3.7em;
    height: 85%;
    width: 85%;
  }
}
