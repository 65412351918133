.containerGeneral {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
}

.containerQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.containerGeneralInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50%;
  margin-bottom: 20px;
}

.containerSocial {
  display: flex;
  gap: 0.5em;
  margin-top: 100%;
}
.containerSocial a svg {
  color: var(--green);
  padding: 1px;
  display: block;
}

.titleQuestion {
  color: #090053;
  margin: 40px;
}

.containerContact {
  color: #2dd0a4;
  font-size: 1rem;
  margin-top: 1em;
}
