.box {
  width: 100%;
}

.centro {
  text-align: center;
}

.centro2 {
  text-align: center;
  margin-top: 0%;
}

.cardCount {
  display: 'flex';
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.dialog.btnCerrar {
  margin-right: 0%;
}

.btnbox {
  align-items: center;
}

.btnCerrar {
  margin-left: 40%;
  margin-top: -4%;
  position: absolute;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}
.lowerLetter {
  color: var(--white) !important;
  background-color: var(--green) !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}
.paragraphButton {
  display: flex;
  justify-content: center !important;
}
