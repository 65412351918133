.dashboardComparison {
  margin: 25px;
  margin-top: 90px;
}

.dashboardComparison .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
} 

.dashboardComparison .position {
  display: flex;
  place-content: center;
  align-items: center;
  flex-direction: column;
  align-content: center;
  /* margin-top: 2em; */
  gap: 1em;
}

.dashboardComparison .titleComparison {
  text-align: center;
  color: #090053;
  margin-bottom: 10px;
}

.dashboardComparison .graphic {
  display: flex;
  place-content: center;
  flex-direction: row;
  margin-top: 3em;
  grid-gap: 1em;
  gap: 1em;
  flex-wrap: nowrap;
  align-items: center;
}

.dashboardComparison .titleDashboard {
  text-align: center;
  color: #090053;
  padding-top: 5px;
}

.dashboardComparison .footerBarGraphic {
  color: #616161;
  font-size: 10px;
  margin: -100px 207px 34px;
}

.dashboardComparison .styleBar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:right ;
  margin: 5px 207px 10px;
  position: relative;
  text-align: right;
  gap: 1em;
}


/* .dashboard_reports .labelInputs {

} */

