body {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.main-container {
  height: 100%;
  width: 100%;
  text-align: center;
}
.main-container h3 {
  max-width: 40em;
  margin: auto;
}

.anchor-internet {
  text-decoration: none;
  font-size: 1.5rem;
}

.logo-internet {
  min-width: 1em;
  max-width: 55em;
}

.error-internet {
  margin-bottom: 20px;
}
