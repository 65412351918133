.containerDashboardTypes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.containerDashboardTypes h2 {
  color: #090053;
  font-size: 35px;
}

.textSpan1 {
  color: #2dd0a4;
  font-weight: bold;
}

.textSpan {
  color: #2dd0a4;
  font-weight: bold;
}

.textSpan:hover{
  cursor: pointer;
}

.containerDashboardTypes .buttonComponent {
  margin-top: 1.4em;
}

.titleWelcome {
  color: #090053;
  font-size: 30px;
}
.titleVideoWelcome {
  color: #090053;
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
}

.titleCard {
  color: #090053;
}

.containerCards {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  gap: 3em;
}

.cardDashboardsTypes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.3em;
  box-shadow: 0px 7px 20px #21202133;
  border-radius: 1em;
  margin-block-end: 2em;
  cursor: pointer;
  height: 400px;
  width: 239px;
  transition: transform 0.3s ease-in-out;
}
.cardDashboardsTypes:hover {
  transform: scale(1.1);
}

.imgHistorico {
  width: 170px;
  height: 135px;
}

.cardDashboardsTypes p {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 0;
  margin-top: 15px;
  text-align:justify;
}
.closeVideo{
  text-align: right;
  color: #2dd0a4;
  border-radius: 5px;
}
.video{
  align-items: center;
  margin-left: 168px;
}
