* {
  padding: 0;
  margin: 0;
  border: 0;
}

.outer-container:hover {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
  transform: scale(1.25);
}


.boxShadow .MuiPaper-elevation3,
.MuiPaper-rounded {
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12) !important;
  border-radius: 12px !important;
}
