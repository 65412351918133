.footerTop {
  display: flex;
  justify-content: space-evenly;
  padding-block-start: 6.8em;
  padding-block-end: 4em;
  flex-wrap: wrap;
}
.footerSocialMedia {
  width: 21em;
}
.footerLogo img {
  width: 100%;
}
.socialMedia {
  display: flex;
  gap: 0.8em;
  margin-block-start: 2em;
}
.socialMedia li {
  list-style: none;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}
.socialMedia li svg {
  color: var(--green);
  padding: 5px;
  display: block;
}
.socialMedia li svg:active {
  transform: scale(0.9);
}
.footerBottom {
  display: flex;
  grid-gap: 10em;
  color: var(--white);
  margin-inline-start: 3em;
  margin-inline-end: 3em;
  justify-content: space-evenly;
  padding-block-end: 2em;
}
.footerRight {
  display: flex;
  gap: 0.5em;
  align-items: center;
}
.footer p {
  margin-left: 0;
  margin-top: 0;
}
.footerRight p a{
  color: var(--white);
  text-decoration: none;
}
.footerRight p:nth-child(2) {
  content: " ";
  height: 1.5em;
  border-left: 2px solid var(--white);
  display: flex;
  align-items: center;
  padding-inline-start: 6px;
}
.footerRight img {
  width: auto;
  height: 1.5em;
}
.footerContact {
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-block-end: 3em;
}
.footerContact li {
  list-style: none;
  margin: 0;
}
.footerContact li a {
  color: var(--white);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
}
.footerContact li svg:active {
  color: var(--green);
  transform: scale(0.9);
}

/* Media Query */
@media screen and (max-width: 369px) {
  .footerTop {
    padding-inline-start: 1em;
    padding-inline-end: 1em;
    gap: 3em;
    justify-content: flex-start;
  }
  .socialMedia{
    justify-content: center;
  }
  .footerContact{
    gap: 1em;
  }
  .footerBottom {
    flex-direction: column;
    align-items: center;
    padding-inline-start: 1em;
    padding-inline-end: 1em;
    gap: 2em;
    margin: 0;
  }
  .footerLeft{
    text-align: center;
  }
  .footerRight {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footerRight:nth-child(1){
    margin-block-end: 1em;
  }
  .footerRight p:nth-child(2){
    border-left: 0;
  }

}
@media screen and (min-width: 370px) and (max-width: 480px) {
  .footerTop {
    justify-content: flex-start;
    margin-inline-start: 2em;
    gap: 1.5em;
  }
  .footerBottom {
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    margin: 0;
  }
  .footerRight {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .footerTop {
    justify-content: flex-start;
    margin-inline-start: 3em;
    margin-inline-end: 3em;
    gap: 4.5em;
  }
  .footerBottom {
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    margin: 0;
  }
  .footerRight {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 890px) {
  .footerBottom {
    gap: 0;
  }
  .footerLeft {
    width: 256px;
  }
}
@media screen and (min-width: 890px) and (max-width: 1024px) {
  .footerBottom {
    gap: 2em;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .footerBottom {
    gap: 4em;
  }
}
