.loader {
  width: 80px;
  height: 80px;
  display: flex;
  place-content: center;
  place-items: center;
  position: absolute;
  top: 40%;
  left: 47%;
  transform: translate(-50%, -50%);
  animation: loading 2s infinite;
  z-index: 9999; /* Asegura que el loader esté por encima de todo lo demás */
}
.loader img {
  max-width: 100%;
  animation: loading-icon 2s infinite;
}

@keyframes loading {
  0% {
    transform: perspective(250px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(250px) rotateX(180deg) rotateY(0deg);
  }
  100% {
    transform: perspective(250px) rotateX(180deg) rotateY(-180deg);
  }
}
@keyframes loading-icon {
  0% {
    transform: perspective(250px) rotateX(0deg) rotateY(0deg);
  }
  15% {
    transform: perspective(250px) rotateX(0deg) rotateY(0deg);
  }
  16% {
    transform: perspective(250px) rotateX(180deg) rotateY(0deg);
  }
  50% {
    transform: perspective(250px) rotateX(180deg) rotateY(0deg);
  }
  65% {
    transform: perspective(250px) rotateX(180deg) rotateY(0deg);
  }
  66% {
    transform: perspective(250px) rotateX(180deg) rotateY(180deg);
  }
  100% {
    transform: perspective(250px) rotateX(180deg) rotateY(180deg);
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000b6;
  z-index: 9998;
}
