.containerGraphicStacked {
    padding-bottom: 4rem;
    width: 600px;
    height:430px;
    align-items: center;
}
.textCommentsGraphicStacked {
    color: #090053;
    font-size: 10px;
     margin: 20px 203px 5px;
}
.footerGraphicStacked {
    color: #616161;
    font-size: 10px;
    margin: 5px 195px 40px;
}
.styleBarStacked{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1;
    margin-left: 50;
    position: relative;
    text-align: center;
    top: -1em;
    gap: 1em;
}
.styleBarStackedLabel{
    padding-left: 160px;
}
