.confirm_register {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-image: url('../../../static/img/landingPage/Fondo_home.jpg');
  background-position: top;
  background-repeat: no-repeat;
}

.confirm_register .card {
  display: flex;
  width: 500px;
}

.confirm_register h6 {
  text-align: center;
  color: #090053;
}

.position {
  display: flex;
  justify-content: center;
}

.confirm_register .space {
  margin-top: 5%;
}

.confirm_register .space button {
  margin-top: 5%;
}

.confirm_register .MuiInputBase-input {
  min-width: 200px;
  background-color: transparent;
  color: #575757;
  padding: 13px 15px 10px 13px;
  border-radius: 12px;
  border: #090053 2px solid;
}

.confirm_register .MuiInput-underline:before {
  border: none !important;
  width: 50%;
}

.confirm_register .MuiInput-underline:after {
  border: none !important;
  width: 50%;
}

.confirm_register .MuiFormHelperText-root.Mui-error {
  color: rgb(255, 0, 0);
  padding: 5px 10px 5px 10px;
}

.confirm_register .label {
  text-align: center;
  color: #2dd0a4;
  font-display: swap;
  font-size: 15px;
}

.form {
  min-width: 250px;
  max-width: 350px;
}


.confirm_register button.ConfirmRegister {
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  text-transform: capitalize;
}

.confirm_register button.ConfirmRegister:hover {
  background-color: #2dd0a4;
}

