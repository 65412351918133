@import url('https://fonts.googleapis.com/css?family=Poppins:700,500,400|Rubik&display=swap');
:root {
  --white: #fff;
  --green: #2dd0a4;
  --blue: #090053;
}

body,
html,
#app,
#app > div,
#root {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: auto;
}

body {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f8f9 !important;
}

.card {
  border: 2.5px solid #2dd0a4;
}
.h4 {
  text-align: center;
  color: #090053;
}


/* .css-2b097c-container .css-5duvv3-control:hover,
.css-2b097c-container .css-5duvv3-control,
.css-2b097c-container .css-1vp9nu5-control {
  background-color: transparent;
  border: #090053 2px solid;
  border-radius: 12px;
  color: #575757;
}

.MuiTableCell-root.MuiTableCell-head {
  background: #b1f1cf;
  color: var(--blue);
}

.MuiIconButton-label {
  color: #2dd0a4;
}

.css-6q0nyr-Svg {
  color: #2dd0a4;
}

.MuiPaper-root.MuiCard-root.makeStyles-border-126.MuiPaper-elevation1.MuiPaper-rounded {
  border: #090053 10px solid;
  color: #090053;
}

.MuiChip-root.MuiChip-outlined.MuiChip-outlinedPrimary {
  border: #090053 2px solid;
  color: #090053;
}

.period .MuiInputBase-input {
  min-width: 200px;
  margin-left: 13px;
  background-color: transparent;
  color: #575757;
  padding: 13px 15px 10px 13px;
  border-radius: 12px;
  border: #090053 2px solid;
}

.period .MuiInput-underline:before {
  border: none !important;
  width: 50%;
}

.period .MuiInput-underline:after {
  border: none !important;
  width: 50%;
}

.period button.button {
  background-color: #2dd0a4;
  border-radius: 12px;
  margin: 3em 32%;
  color: #090053;
  text-transform: capitalize;
}

.period .MuiFormHelperText-root.Mui-error {
  color: rgb(255, 0, 0);
  padding: 5px 10px 5px 10px;
}

.period .label {
  text-align: center;
  color: #090053;
  font-display: swap;
  font-size: 15px;
}

.period .space {
  padding-bottom: 5%;
} */
