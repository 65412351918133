.login {
  background-image: url('../../../static/img/landingPage/Fondo_home.jpg');
  height: 100vh;
  display: flex;
  place-content: center;
  align-items: center;
  background-position: top;
  background-repeat: no-repeat;
}
.login .cardLogin {
  width: 28em;
  height: 37em;
  border-radius: 1em !important;
  display: flex;
  place-items: center;
}

.login .content {
  text-align: center;
}
.contentForm {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.container-custom .image img {
  width: 15em;
}
.containerLogin {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}
.login .row {
  gap: 2em;
}
.login .row #textlogin1 {
  color: var(--green);
  font-weight: 300;
}
.login .MuiInputBase-root {
  border: 2px solid var(--blue);
  border-radius: 12px;
  width: 275px;
}

.buttons button.btn-register {
  font-family: fuente3, sans-serif;
  background-color: #2dd0a4;
  color: #090053;
  width: 180px;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 15px;
  border-radius: 12px;
}
/* Para inputs autocompleted */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: var(--gray) !important;
  transition: background-color 5000s ease-in-out 0s;
  border-radius: 12px;
}
.MuiIconButton-root {
  padding: 0;
}
.login .MuiInputBase-input {
  padding: 10px 0 10px 15px;
}
.login .MuiInputBase-input#username {
  padding: 10px 15px;
}
.inputLogin {
  padding-inline-start: 0.5em;
}
.login .MuiInput-underline:after,
.login .MuiInput-underline:before {
  border: none !important;
}

.row #text {
  margin-top: 9px;
  /* margin: 0; */
  color: #575757;
}
.privacy-and-terms .MuiTypography-colorPrimary {
  color: #575757 !important;
}
.singUp {
  display: flex;
  gap: 10px;
  place-content: center;
}
.singUp p {
  margin: 0;
}
.singUp p:nth-child(2) {
  color: #090053;
  cursor: pointer;
  font-weight: 600;
}

.social-media {
  display: flex;
  place-content: center;
  margin-top: 1em;
}

.social-media .buttonGoogle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  padding: 12px 2em;
  background-color: #e8e8e8;
  color: var(--blue);
  border-radius: 12px;
  cursor: pointer;
  font-family: fuente3, sans-serif;
  font-size: 15px;
}
.social-media img {
  width: 1em;
}
.social-media .buttonGoogle span {
  color: var(--blue);
}
.login .social-media .buttonGoogle:active {
  transform: scale(0.9);
}
.privacy-and-terms a {
  color: #575757;
  cursor: pointer;
}
.login .MuiFormHelperText-root.Mui-error {
  color: #ff5353;
  padding: 5px 10px;
  position: absolute;
  top: 38px;
  left: -8px;
  transition: all 0.3s ease-in-out;
}
.login .Mui-error.MuiInputBase-root {
  border-color: #ff5353;
}

/*? Media Queries */

@media screen and (min-width: 250px) and (max-width: 310px) {
  .login .cardLogin {
    width: 15em;
    height: 33em;
  }
  .content .contentForm {
    gap: 1em;
  }
  .container-custom .image img {
    width: 11em;
  }
  .login .MuiInputBase-root {
    width: 200px;
  }
  .login .MuiInputBase-input {
    font-size: 12px;
  }
  .buttons button.btn-register {
    width: 120px;
  }
  .singUp {
    font-size: 10px;
  }
  .social-media .buttonGoogle {
    padding: 8px 0.5em;
  }
  .privacy-and-terms a {
    font-size: 13px;
  }
  .login .MuiFormHelperText-root.Mui-error {
    font-size: 9px;
  }
}
@media screen and (min-width: 310px) and (max-width: 360px) {
  .login .cardLogin {
    width: 17em;
  }
  .content .contentForm {
    gap: 1em;
  }
  .container-custom .image img {
    width: 11em;
  }
  .login .MuiInputBase-root {
    width: 200px;
  }
  .login .MuiInputBase-input,
  .privacy-and-terms a {
    font-size: 15px;
  }
  .buttons button.btn-register {
    width: 120px;
  }
  .singUp {
    font-size: 13px;
  }
  .social-media .buttonGoogle {
    padding: 8px 0.5em;
  }
  .login .MuiFormHelperText-root.Mui-error {
    font-size: 13px;
  }
}
@media screen and (min-width: 360px) and (max-width: 410px) {
  .login .cardLogin {
    width: 20em;
  }
  .content .contentForm {
    gap: 1em;
  }
  .container-custom .image img {
    width: 11em;
  }
  .login .MuiInputBase-root {
    width: 250px;
  }
  .login .MuiInputBase-input,
  .privacy-and-terms a {
    font-size: 15px;
  }
  .buttons button.btn-register {
    width: 120px;
  }
  .singUp {
    font-size: 13px;
  }
  .social-media .buttonGoogle {
    padding: 8px 0.5em;
  }
  .login .MuiFormHelperText-root.Mui-error {
    font-size: 13px;
  }
}
@media screen and (min-width: 410px) and (max-width: 470px) {
  .login .cardLogin {
    width: 24em;
    height: 34em;
  }
  .content .contentForm {
    gap: 1em;
  }
  .container-custom .image img {
    width: 11em;
  }
  .login .MuiInputBase-root {
    width: 250px;
  }
  .login .MuiInputBase-input,
  .privacy-and-terms a {
    font-size: 15px;
  }
  .buttons button.btn-register {
    width: 120px;
  }
  .singUp {
    font-size: 13px;
  }
  .social-media .buttonGoogle {
    padding: 8px 0.5em;
  }
  .login .MuiFormHelperText-root.Mui-error {
    font-size: 13px;
  }
}
@media screen and (min-width: 470px) and (max-width: 570px) {
  .login .cardLogin {
    width: 22em;
    height: 32em;
  }
  .content .contentForm {
    gap: 1em;
  }
  .container-custom .image img {
    width: 11em;
  }
  .login .MuiInputBase-root {
    width: 250px;
  }
  .login .MuiInputBase-input,
  .privacy-and-terms a {
    font-size: 15px;
  }
  .buttons button.btn-register {
    width: 120px;
  }
  .singUp {
    font-size: 13px;
  }
  .social-media .buttonGoogle {
    padding: 8px 0.5em;
  }
  .login .MuiFormHelperText-root.Mui-error {
    font-size: 13px;
  }
}
