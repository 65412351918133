body,
p,
input {
  font-family: "Rubik", sans-serif;
}

h1,
h2,
/* h3, */
h4,
h5,
h6,
label,
.MuiFormHelperText-root {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
}
