.dashboard_reports {
  height: 50%;
  width: 100%;
}

.dashboard_reports .title {
  text-align: center;
  color: #090053;
}
.titles{
  text-align: center;
  color: #090053;
  padding-top: 5px;
}

.dashboard_reports .margin {
  margin: 1%;
}

.dashboard_reports .border {
  border: #2dd0a4 2px solid;
}
.dashboard_reports button.button {
  background-color: #2dd0a4;
  color: #090053;
  text-transform: capitalize;
  font-display: swap;
  border-radius: 12px;
  margin-top: 50px;
}

.dashboard_reports .icon,
.dashboard_reports .MuiCardHeader-title,
.dashboard_reports .MuiCardHeader-subheader {
  color: #090053;
  font-display: swap;
}

.dashboard_reports .card_size {
  width: 300px;
  height: 80px;
}

.dashboard_reports .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
} 

.dashboard_reports .titulo {
  text-align: center;
}
.dashboard_reports .textField {
  padding: 20px;
  padding-left: 0px;
  margin: 15px;
}
.dashboard_reports .position {
  display: flex;
  place-content: center;
  align-items: center;
  flex-direction: column;
  align-content: center;
  margin-top: 2em;
  gap: 1em;
}
