.change_password_dialog .MuiInput-underline:before {
  border: none !important;
  width: 50%;
}

.change_password_dialog .MuiInput-underline:after {
  border: none !important;
  width: 50%;
}

.change_password_dialog .MuiFormHelperText-root.p.Mui-error {
  color: rgb(255, 0, 0);
  text-align: center;
}

.change_password_dialog .label {
  text-align: center;
  color: #090053;
}

.change_password_dialog button.button {
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  text-transform: capitalize;
}
.change_password_dialog button.no-main {
  background-color: transparent;
  color: var(--blue);
  border-radius: 8px;
  border: 2px solid var(--blue);
}

.change_password_dialog .MuiSvgIcon-root {
  color: #2dd0a4;
}

.change_password_dialog .MuiInputBase-input {
  min-width: 200px;
  background-color: transparent;
  color: #575757;
  padding: 13px 15px 10px 13px;
  border-radius: 8px;
  border: #090053 2px solid;
}

.change_password_dialog .dialog-title {
  color: #090053;
  text-align: center;
}

.change_password_dialog .icon {
  margin-left: -45px;
}

.change_password_dialog .position {
  margin-top: 3%;
  display: flex;
  place-content: center;
}
