.graphicsContainer {
  margin: 20px;
  /* padding: 20px; */
  margin-top: 90px;
}
.ReactWordcloud:read-only {
  text-align: -webkit-center;
  background-repeat: no-repeat;
  background-position: center;
}

.formControl {
  width: 100%;
}
.formControl .MuiFormLabel-root {
  padding-left: 1em;
}
.formControl .MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.tableReport {
  margin: 10px;
  min-width: 400;
  max-width: 800;
}

.pagination {
  flex-shrink: 0;
  margin-left: 8px;
}
.tableContainer {
  margin-top: -2%;
  /* height: 50%; */
  height: 600px;
  overflow-y: scroll; 
  overflow-x: auto;
}

.graphicContainerVariation {
  width: 95%;
  margin-right: 5px;
  margin-top: 60px;
}
.graphics {
  width: 100%;
  height: 50%;
}

/* .ReactWordcloud {
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
} */

.buttonReturn {
  background-color: #2dd0a4;
  color: #090053;
  text-transform: capitalize;
  font-display: swap;
  border-radius: 12px;
  margin-top: 50px;
}

.roott {
  flex-grow: 1;
}
.paper {
  padding: 8px;
  text-align: center;

  border: solid 3px #2dd0a4;
  border-radius: 20px;
}
.paperPlutchik {
  width: 650px;
  height: 460px;
  margin-bottom: 15px;
  margin-right: 50px;
  padding: 2px;
}
.paperGraphicGender {
  display: flex;
  justify-content: center;
}
.control {
  padding: 8px;
}





