button.buttonComponent {
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  text-transform: capitalize;
}

button.buttonComponent:hover {
  background-color: #2dd0a4;
 
}
button.buttonComponent:active {
  transform: scale(0.9);
}
.buttonSecondary {
  color: var(--blue) !important;
  background-color: transparent !important;
  border: 2px solid var(--blue) !important;
  border-radius: 8px;
  text-transform: capitalize;

}
.buttonSecondary:active {
  transform: scale(0.9);
}
