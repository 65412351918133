.forgot_password {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-image: url('../../../static/img/landingPage/Fondo_home.jpg');
}

.forgot_password .card {
  display: flex;
  width: 700px;
}

.forgot_password .center {
  text-align: center;
}

.forgot_password h6 {
  text-align: center;
  color: #090053;
}

.position {
  display: flex;
  place-content: center;
}

.forgot_password .space {
  margin-top: 5%;
}

.forgot_password .space button {
  margin-top: 2.5%;
}

.forgot_password .MuiInputBase-input {
  min-width: 200px;
  background-color: transparent;
  color: #575757;
  padding: 13px 15px 10px 13px;
  border-radius: 12px;
  border: #090053 2px solid;
}

.forgot_password .MuiInput-underline:before {
  border: none !important;
  width: 50%;
}

.forgot_password .MuiInput-underline:after {
  border: none !important;
  width: 50%;
}

.forgot_password .MuiFormHelperText-root.Mui-error {
  color: rgb(255, 0, 0);
  padding: 5px 10px 5px 10px;
}

.forgot_password .label {
  font-size: 15px;
  text-align: center;
  color: #2dd0a4;
}

.form {
  min-width: 250px;
  max-width: 350px;
}

.forgot_password .icon {
  margin-left: -50px;
}

.forgot_password .icon_color {
  color: #2dd0a4;
}

.forgot_password button.buttonPassword {
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  text-transform: capitalize;
}

.forgot_password button.buttonPassword:hover {
  background-color: #2dd0a4;
}
