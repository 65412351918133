.containerForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2em;
  /* margin-top: 15px; */
  margin: 20px;
}

.titleModal {
  display: flex;
  color: #090053;
  font-size: 25px;
  padding: 15px;
  justify-content: center;
}

.icon {
  margin-left: -35px;
}

.iconColor {
  color: #2dd0a4;
}

.InputModal {
  display: flex;
  flex-direction: row;
  grid-column-gap: 2em;
}

.label {
  font-size: 15px;
  text-align: center;
  color: #2dd0a4;
}

.containerForm .input-chip .MuiFormControl-root {
  width: 240px;
  background-color: transparent;
  color: #575757;
  padding: 7px 11px;
  border-radius: 10px;
  border: #090053 2px solid;
  margin: 0;
}
.TimeFrecuencia{
  width: 240px;
  height: 55px;
  border-width: 2px ;
  border-color: #090053;
  border-radius: 10px;
  border: #090053 2px solid;
  padding: 3px;
  background-color: transparent;
}

.containerForm .input-chipSocial .MuiFormControl-root {
  width: 240px;
  background-color: transparent;
  color: #575757;
  padding: 10px;
  border-radius: 10px;
  border: #090053 2px solid;
}

.MuiInput-underline:before {
  border: none !important;
  width: 50%;
}

.MuiInput-underline:after {
  border: none !important;
  width: 50%;
}

button.buttonCreate {
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  text-transform: capitalize;
}

button.buttonCreate:hover {
  background-color: #2dd0a4;
}
button.buttonCreate:active {
  transform: scale(0.9);
}

/* .swal2-container {
  z-index: 4000;
} */
.containerButtons {
  display: flex;
  gap: 2em;
}
.buttonOperator{
  color:gray;
}

