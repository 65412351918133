.container_BarGraphic{
  display: block;
  flex-direction: row;
  flex-wrap: nowrap;
  width:620px;
  height: 500px;
  margin: 80px 2px 10px;
  padding: 2px;
}


  