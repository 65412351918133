.edit_dashboard .MuiDialogContent-root .MuiInput-underline:before {
  border: none !important;
  width: 50%;
}

.edit_dashboard .MuiDialogContent-root .MuiInput-underline:after {
  border: none !important;
  width: 50%;
}

.edit_dashboard .MuiDialogContent-root .MuiFormHelperText-root.p.Mui-error {
  color: rgb(255, 0, 0);
  text-align: center;
}

.edit_dashboard .MuiDialogContent-root .label {
  text-align: center;
  color: #090053;
}

.edit_dashboard .MuiDialogContent-root {
  overflow-x: hidden;
  overflow-y: auto;
}

.edit_dashboard .MuiDialogContent-root .input-chip .MuiFormControl-root {
  min-width: 200px;
  background-color: transparent;
  color: #575757;
  padding: 10px 10px 5px 10px;
  border-radius: 8px;
  border: #090053 2px solid;
}

.edit_dashboard .MuiDialogContent-root button.no-main,
.edit_dashboard .MuiDialogContent-root button.button {
  background-color: #2dd0a4;
  color: #090053;
  text-transform: capitalize;
  font-display: swap;
  border-radius: 8px;
  margin-bottom: 3%;
}

.edit_dashboard .MuiDialogContent-root button.no-main {
  background-color: transparent;
  border: #2dd0a4 2px solid;
  color: #090053;
  text-transform: capitalize;
  font-display: swap;
  border-radius: 8px;
  margin-bottom: 3%;
}

.edit_dashboard .MuiDialogContent-root .icon {
  margin-left: -35px;
}
.edit_dashboard .MuiDialogContent-root .MuiSvgIcon-root {
  color: #2dd0a4;
}
.edit_dashboard .MuiDialog-paperFullWidth{
  height: 40em;
}

.edit_dashboard .MuiDialogContent-root .MuiInputBase-input {
  min-width: 200px;
  background-color: transparent;
  color: #575757;
  padding: 13px 15px 10px 13px;
  border-radius: 8px;
  border: #090053 2px solid;
}

.edit_dashboard .MuiDialogContent-root .input-chip .MuiInputBase-input {
  border: none;
}

.edit_dashboard .MuiDialogContent-root .dialog-title {
  color: #090053;
  text-align: center;
}

.edit_dashboard .MuiDialogContent-root .position {
  margin-top: 3%;
  display: flex;
  place-content: center;
}
.map .map_container{
  height: 38vh;
}
.modalRow{
  justify-content: center;
}