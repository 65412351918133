.containerFormHistory {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2em;
  /* margin-top: 15px; */
  margin: 20px;
}

.titleModal {
  display: flex;
  color: #090053;
  font-size: 25px;
  padding: 15px;
  justify-content: center;
}

.icon {
  margin-left: -35px;
}

.iconColor {
  color: #2dd0a4;
}

.containerFormHistory .InputModal {
  display: flex;
  flex-direction: row;
  grid-column-gap: 3em;
}

.label {
  font-size: 15px;
  text-align: center;
  color: #2dd0a4;
}

.containerFormHistory .input-chip .MuiFormControl-root {
  width: 240px;
  background-color: transparent;
  color: #575757;
  padding: 7px 11px;
  border-radius: 10px;
  border: #090053 2px solid;
  margin: 0;
}

.containerFormHistory .input-chipSocial .MuiFormControl-root {
  width: 240px;
  background-color: transparent;
  color: #575757;
  padding: 10px;
  border-radius: 10px;
  border: #090053 2px solid;
}

.MuiInput-underline:before {
  border: none !important;
  width: 50%;
}

.MuiInput-underline:after {
  border: none !important;
  width: 50%;
}

.swal2-popup.swal2-modal {
  border-radius: 12px;
  color: #090053;
  border: #2dd0a4 solid 4px;
}

button.buttonCancel {
  background-color: transparent;
  border: #2dd0a4 2px solid;
  color: #090053;
  text-transform: capitalize;

  border-radius: 12px;
}
button.buttonCancel:hover {
  background-color: transparent;
}

/* .containerButtons {
  display: flex;
} */
/* .swal2-container {
  z-index: 4000;
} */
.containerButtons {
  display: flex;
  gap: 2em;
}