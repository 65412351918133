.update_user .MuiInput-underline:before {
  border: none !important;
  width: 50%;
}

.update_user .MuiInput-underline:after {
  border: none !important;
  width: 50%;
}

.update_user .MuiFormHelperText-root.p.Mui-error {
  color: rgb(255, 0, 0);
  text-align: center;
}

.update_user .label {
  text-align: center;
  color: #090053;
}

.update_user .title {
  text-align: center;
  color: #090053;
}

.update_user button.button {
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  text-transform: capitalize;
  width: 40%;
}

.update_user button.no-main {
  color: var(--blue);
  background-color: transparent;
  border: 2px solid var(--blue);
  border-radius: 8px;
  text-transform: capitalize;
  width: 40%;
}

.update_user .MuiSvgIcon-root {
  color: #2dd0a4;
}

.update_user .MuiInputBase-input {
  min-width: 200px;
  background-color: transparent;
  color: #575757;
  padding: 13px 15px 10px 13px;
  border-radius: 12px;
  border: #090053 2px solid;
}

.update_user {
  color: #090053;
  text-align: center;
}

.update_user .icon {
  margin-left: -45px;
}

.update_user .position {
  margin-top: 3%;
  display: flex;
  place-content: center;
}
