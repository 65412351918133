* {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }
  .chartMenu {
    width: 100vw;
    height: 40px;
    background: #1a1a1a;
    color: rgba(253, 254, 254);
  }
  .chartMenu p {
    padding: 10px;
    font-size: 20px;
  }
  .chartCard {
    display: flex;
    width: 90vw; 
    height: calc(100vh - 40px); 
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-left: 40px;
  }
  .chartContainer{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

  }
  .chartBox {
    display:block;
    width: 80vw;
    height: 50vw;
    padding: 20px;
    border-radius: 20px;
    border: solid 3px #2dd0a4;
    background: white;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    padding: 15px;
    font-size: 30px;
    font-weight: bold;
    background: #2dd0a4;
    color: white;
    cursor: pointer;
    margin: 10px;
  }

  .textGenerated {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #616161;
    font-size: 10px;
    margin-top:5px ;
    margin-bottom: 10px;
  } 
  
  .textCommentsGraphic {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #090053;
    font-size: 10px;
 }
  .styleRegressionLineal{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 1em;
  }
  