 .graphicContainer {
    width: 600px;
    margin-right: 5px;
    margin-top: 60px;
    margin-left: 70px;
  } 
  .titlePlutchik { 
    color: #090053;
    font-size: 17px;
    margin-top: -50px;
    margin-bottom: 12px;
    text-align: center;
  }
  .textCommentsPlutchik {
    color: #090053;
    font-size: 11px;
    margin-top: 5px;
    text-align: center;
  }
  .raphic_gender_Plutchick{
    height:350px;
  }  
  .footerPlutchik{
    color: #616161;
    font-size: 10px;
    margin-left: 220px ; 
    padding: 5px;
  }
  .stylePlutchik{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 2;
    text-align: center;
    gap: 1em;
  }
 