.DashboardGraphics .buttonCloud {
  background-color: #2dd0a4;
  color: #090053;
  text-transform: capitalize;
  font-display: swap;
  border-radius: 12px;
}
.graphicContainer1 {
  width: 620px;
  height: 670px;
  margin-right: 5px;
  margin-top: 30px;
  margin-left: 70px;
} 
.titleWordsCloud {
  color: #090053;
  width: 100%;
  background-color: #dfe2e5;
  height: 40px;
  font-size: 20px;
  border-radius: 12px;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.btnExcel button {
  position: relative;
  left: 87%;
  top: -217px;
  color: var(--white);
  background-color: var(--green);
  border-radius: 8px;
  text-transform: capitalize;
  padding: 6px 16px;
  font-size: 16px;
  line-height: 24.5px;
  cursor: pointer;
}
.styleCloud{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 5px;
  gap: 1em;
}
.textCommentsCloud {
  color: #090053;
  font-size: 11px;
  text-align: center;
  margin: -75px 155px 13px;
}

.cloudDownload{
  width: 530px;
  height:510px;
  align-items: center;
}
.footerCloud{
  color: #616161;
  font-size: 10px;
  margin: -10px 165px 20px;
}
.textCommentsBarGraphic {
  color: #090053;
  font-size: 10px;
  margin: -150px 215px 2px;
}
.footerBarGraphic {
  color: #616161;
  font-size: 10px;
  margin: 5px 207px 60px;
}
.styleBar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:right ;
  margin: 5px 207px 10px;
  position: relative;
  text-align: right;
  gap: 1em;
}