.container_raphic_gender {
  width: 450px;
  height: 450px;
  margin-bottom: 5px;
  margin-top: -7px;
}
.textComments {
  color: #090053;
  font-size: 11px;
  margin: 3%;
  text-align: center;
}
.comments_gender {
  width: 600px;
  height: 590px;
  padding-left: 78px;
}
.footerComments {
  color: #616161;
  font-size: 10px;
  margin: 35px 125px 20px;
}
.stylePieGraphic{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2;
  position: relative;
  text-Align: center;
  gap: 1em;
}
