.text1 {
  color: #2dd0a4;
  font-size: 1em;

  text-align: right;
}

.text2 {
  color: #090053;
  text-align: left;
  font-size: 1em;
}

.buttonCenter {
  text-align: center;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn {
  border-radius: 12px;
  background: #2dd0a4;
  color: #090053;
  margin-top: 5%;
  text-transform: capitalize;
}

.imagen2 {
  padding-bottom: 5%;
}

.Typography {
  text-align: center;
}
.tableSubs {
  padding-top: 5%;
}

.buttons {
  display: flex;
  gap: 1em;
  margin-top: 1em;
  justify-content: center;
}
