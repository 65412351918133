.updateTable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  position: relative;
  padding-bottom:40px;
  text-align: center;
  gap: 1em;
}


