.titlesPrivacy {
  color: #090053;
  font-size: 20px;
  margin-top: 30px;
  text-align: center;
  width: 25em;
}

.pPolicies {
  color: #424242;
  font-size: 18px;
  text-align: justify;
  width: 60%;
}

.imgIcon {
  width: 40px;
  margin-right: 10px;
}

.containerTitle {
  margin-top: 50px;
  color: #090053;
  font-size: 30px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.containerPolicies {
  color: #090053;
  font-size: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.containerFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6%;

}
.containerRedes {
  display: flex;
  gap: 0.5em;
  
}

.containerRedes a svg {
  color: var(--green);
  padding: 1px;
  display: block;
}
.containerInfo {
  color: #2dd0a4;
  font-size: 1rem;
  margin-top: 1em;

}
