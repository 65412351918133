.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5em;
  margin-block-start: 3em;
}
.containerCard {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  box-shadow: 0px 7px 20px #21202133;
  border-radius: 1em;
  margin-block-end: 2em;
  gap: 1.5em;
  max-width: 270px;
}
.cardTop {
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 2em;
}
.cardTop .cardHeader h3 {
  color: #090053;
  font-size: 24px;
}
.cardPrice h2::before {
  /* content: 'USD $'; */
  font-size: 22px;
  font-weight: 400;
}
.cardPrice h2 {
  font-size: 28px;
  color: #2dd0a4;
  font-weight: 500;
}
.cardPrice h4 {
  text-align: center;
  color: #2dd0a4;
  font-weight: 400;

}
.cardButton button {
  background-color: #2dd0a4;
  color: var(--blue);
  width: 160px;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 18px;
  border-radius: 11px;
  padding: 12px;
  cursor: pointer;
}
.cardButton p {
  margin: 0;
  font-size: 15px;
  line-height: 1;
  text-align: center;
}
.cardButton button:active {
  transform: scale(0.9);
}
.containerCard hr {
  border: 1px solid #090053;
}
.cardInfo {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}
.cardInfo p {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
}
.cardInfo p svg {
  color: #2dd0a4;
  padding-inline-end: 5px;
}

/* Media Queries */
@media screen and (min-width: 250px) and (max-width: 310px) {
  .cards {
    grid-template-columns: auto;
    padding: 1em;
  }
  .containerCard {
    margin-block-end: 0.5em;
  }
  .cardTop {
    gap: 1em;
  }
  .cardInfo p {
    font-size: 12px;
  }
}
@media screen and (min-width: 310px) and (max-width: 360px) {
  .cards {
    grid-template-columns: auto;
    padding: 2em;
  }
  .containerCard {
    margin-block-end: 0.5em;
  }
  .cardTop {
    gap: 1em;
  }
  .cardInfo p {
    font-size: 13px;
  }
}
@media screen and (min-width: 360px) and (max-width: 410px) {
  .cards {
    grid-template-columns: auto;
    padding: 2.5em;
  }
  .containerCard {
    margin-block-end: 0.5em;
  }
  .cardTop {
    gap: 1em;
  }
  .cardInfo p {
    font-size: 14px;
  }
}
@media screen and (min-width: 410px) and (max-width: 600px) {
  .cards {
    grid-template-columns: auto;
    padding: 2.5em;
  }
  .containerCard {
    margin-block-end: 0.5em;
    max-width: 250px;
  }
  .cardTop {
    gap: 1em;
  }
  .cardInfo p {
    font-size: 14px;
  }
}
@media screen and (min-width: 600px) and (max-width: 720px) {
  .cards {
    grid-template-columns: auto;
    padding: 2.5em;
  }
  .containerCard {
    margin-block-end: 0.5em;
  }
  .cardTop {
    gap: 1em;
  }
  .cardInfo p {
    font-size: 16px;
  }
}
@media screen and (min-width: 720px) and (max-width: 1200px) {
  .cards {
    grid-template-columns: auto auto;
  }
}
